// @flow
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import Markdown from '../components/Markdown';
import SectionSingleColumn from '../components/SectionSingleColumn';
import strings from '../config/strings.json';
import {LocationType} from '../types';

class PageText extends React.Component {
  render() {
    const {data, location} = this.props;
    const {pathname: path} = location;
    const {frontmatter, rawMarkdownBody} = data.markdownRemark; // To access more of the frontmatter items, you need to modify the GraphQL query
    const {header, pageMeta} = strings.pages[frontmatter.slug]; // E.g. strings.about... Helps to avoid cluttering the Frontmatter

    return (
      <Layout path={path} pageMeta={{strings: pageMeta}}>
        <SectionSingleColumn header={header}>
          <Markdown
            allowHtml={frontmatter.allowHtml}
            content={rawMarkdownBody}
          />
        </SectionSingleColumn>
      </Layout>
    );
  }
}

PageText.propTypes = {
  data: PropTypes.object.isRequired,
  location: LocationType,
};

export default PageText;

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      rawMarkdownBody
      frontmatter {
        slug
        allowHtml
      }
    }
  }
`;
